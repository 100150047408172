<template>
    <slot :is-open="isOpen" :close-cart="closeCart"></slot>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCartStore } from '@stores/cart';

const cartStore = useCartStore();
const { isOpen } = storeToRefs(cartStore);

const closeCart = () => {
    isOpen.value = false;
};
</script>
