<template>
    <div>
        <div v-if="lineItems.length" class="grid grid-cols-1 gap-2" :class="{ 'loading': loading }">
            <CartListItem v-for="lineItem in lineItems" :key="lineItem.id" :line-item="lineItem" :class="{ 'opacity-25': loading }" />
        </div>

        <div v-else class="flex items-center justify-center p-6" :class="{ 'loading': loading }">
            <p class="text-center text-sm leading-snug text-gray-700 max-w-[200px]">You don't have any items in your order yet</p>
        </div>

        <div class="py-0 px-4 font-semibold tracking-wide text-gray-800 border border-gray-300 bg-gray-100 rounded">
            <dl class="grid grid-cols-2">
                <dt class="py-2">Subtotal</dt>
                <dd class="py-2 text-right">{{ formatCurrency(subtotal) }}</dd>
            </dl>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '@stores/cart';
import CartListItem from './CartListItem.vue';

const cartStore = useCartStore();
const { lineItems, subtotal, loading } = storeToRefs(cartStore);

const formatCurrency = (price) => {
    return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(price);
};

onMounted(() => {
    cartStore.getCart();
});
</script>
