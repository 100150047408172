<template>
    <button type="button" @click.prevent="backToTop">
        <slot>Back to top</slot>
    </button>
</template>

<script setup>

const props = defineProps({
    to: {
        type: String,
        default: '',
    },
});

const getTopPos = () => {
    console.log(props.to);

    if (props.to == '') {
        return 0;
    }

    const el = document.querySelector(props.to);

    const bounds = el?.getBoundingClientRect() ?? null;

    console.log(bounds);

    if (!bounds) {
        return 0;
    }

    return bounds.x + 20;
};

const backToTop = () => {
    const top = getTopPos();

    window.scrollTo({
        top,
        left: 0,
        behavior: 'smooth',
    });
};

</script>
