// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/vue-splide/css/core';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';


// ================================================
// SUI Components
// ================================================


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Pinia
import { createPinia } from 'pinia';

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

import SplideCarousel from '@components/SplideCarousel.vue';
import { SplideTrack, SplideSlide } from '@splidejs/vue-splide';

import PostcodeCheck from '@components/PostcodeCheck.vue';
import ProductForm from '@components/ProductForm.vue';
import CartButton from '@components/CartButton.vue';
import CartDrawer from '@components/CartDrawer.vue';
import CartPreview from '@components/CartPreview.vue';
import DialogTrigger from '@components/DialogTrigger.vue';
import BackToTop from '@components/BackToTop.vue';

import {
    StripeElements,
    StripeElementsCard,
    StripeElementsCardNumber,
    StripeElementsExpiry,
    StripeElementsCvc,
    StripeElementsButton,
    StripeElementsSubmitOnUpdate,
} from '@components/StripeElements';

const pinia = createPinia();


const app = createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiTransitionRoot: TransitionRoot,
        HuiTransitionChild: TransitionChild,
        SplideCarousel,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        PostcodeCheck,
        ProductForm,
        CartButton,
        CartDrawer,
        CartPreview,
        DialogTrigger,
        StripeElements,
        StripeElementsCard,
        StripeElementsCardNumber,
        StripeElementsExpiry,
        StripeElementsCvc,
        StripeElementsButton,
        StripeElementsSubmitOnUpdate,
        BackToTop,
    },

    data() {
        return {
            navActive: false,
        };
    },

    methods: {
        toggleNav() {
            this.navActive = !this.navActive;
        },
    },
});

app.use(pinia);
app.mount('#app');
