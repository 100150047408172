<template>
    <div class="flex w-full gap-3">
        <div class="flex-grow">
            <label class="sr-only" for="postcode">Check your postcode</label>
            <input v-model="postcode" type="tel" class="form-control h-full" placeholder="Enter postcode">
        </div>

        <button class="btn btn-secondary px-4" type="button" @click.prevent="checkPostcode">Check</button>
    </div>

    <p v-if="message" class="text-sm font-light mt-2" :class="message.type === 'error' ? 'text-red-600' : 'text-green-600'">{{ message.text }}</p>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    postcode: {
        type: String,
        required: true,
    },
});

const postcode = ref(props.postcode);
const message = ref(null);

const checkPostcode = async() => {
    fetch('/actions/postcode/base/check', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            postcode: postcode.value,
        }),
    }).then((response) => {
        return response.json();
    }).then((data) => {
        if (data.error) {
            message.value = {
                type: 'error',
                text: data.error.replace('{postcode}', postcode.value),
            };

            return;
        }

        message.value = {
            type: 'success',
            text: 'We deliver to your postcode!',
        };
    });
};
</script>
