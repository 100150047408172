<template>
    <Splide ref="splide">
        <slot :next-slide="nextSlide" :prev-slide="prevSlide"></slot>
    </Splide>
</template>

<script setup>
import { ref } from 'vue';
import { Splide } from '@splidejs/vue-splide';

const splide = ref(null);

const prevSlide = () => {
    splide.value.go('<');
};

const nextSlide = () => {
    splide.value.go('>');
};
</script>
