<template>
    <div class="flex border border-gray-400 rounded overflow-hidden">
        <button :class="buttonClasses" type="button" @click.prevent="decQty">
            <span class="sr-only">Decrease quantity</span>
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path class="fill-current" d="M5 11h14v2H5z" /></svg>
        </button>

        <input v-model="qty" class="w-10 text-gray-900 text-center px-3 border border-gray-200 appearance-none controls-none" type="number">

        <button :class="buttonClasses" type="button" @click.prevent="incQty">
            <span class="sr-only">Increase quantity</span>
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path class="fill-current" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" /></svg>
        </button>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';


const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },

    minQty: {
        type: Number,
        default: 0,
    },

    maxQty: {
        type: Number,
        default: 9999,
    },

    buttonClasses: {
        type: String,
        default: 'btn rounded-none bg-gray-100 text-xs p-1.5',
    },
});

const emit = defineEmits(['update:modelValue']);

const qty = computed({
    get() {
        return props.modelValue;
    },

    set(qty) {
        emit('update:modelValue', qty);
    },
});

const incQty = () => {
    if (qty.value === props.maxQty) {
        return;
    }

    qty.value++;
};

const decQty = () => {
    if (qty.value === props.minQty) {
        return;
    }

    qty.value--;
};
</script>
