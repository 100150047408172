<template>
    <div class="relative grid grid-cols-4 items-start gap-4 py-2">
        <div class="relative aspect aspect-1x1 border border-gray-100 rounded">
            <img class="absolute top-0 left-0 w-full h-full object-contain object-center" :src="lineItem.options.image" :alt="lineItem.snapshot.product.title">
        </div>

        <div class="col-span-3">
            <h4 class="text-sm text-left capitalize text-gray-700">{{ lineItem.snapshot.product.title }}</h4>

            <div class="flex items-center justify-between mt-2">
                <QtyInput :model-value="qty" class="text-xs" button-classes="btn rounded-none bg-gray-100 text-xs p-1" @update:model-value="updateQty" />

                <div>
                    <p class="text-sm text-gray-700">{{ formatCurrency(lineItem.subtotal) }}</p>
                </div>
            </div>
        </div>

        <button class="absolute top-2 right-0 text-gray-400" type="button" @click.prevent="removeItem(lineItem.id)">
            <span class="sr-only">Remove</span>
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path class="fill-current" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /></svg>
        </button>
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { watchDebounced } from '@vueuse/core';
import QtyInput from './QtyInput.vue';

import { useCartStore } from '@stores/cart';
const cartStore = useCartStore();

const props = defineProps({
    lineItem: {
        type: Object,
        required: true,
    },
});

const qty = ref(props.lineItem.qty);

watchEffect(() => {
    // Set the qty to the quantity of the item within the cart if it exists
    if (cartStore) {
        const cartItem = cartStore.getItemById(props.lineItem.purchasableId);

        qty.value = cartItem ? cartItem.qty : 0;
    }
});

// When the qty changes update the cart to match
const updateQty = (value) => {
    cartStore.addItem(props.lineItem, value);
    qty.value = value;
};

const removeItem = (itemId) => {
    cartStore.removeItem(itemId);
};

const formatCurrency = (price) => {
    return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(price);
};

</script>
