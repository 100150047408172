<template>
    <button
        type="button"
        @click.prevent="openCart"
    >
        <slot :total-qty="totalQty"></slot>
    </button>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCartStore } from '@stores/cart';

const cartStore = useCartStore();
const { isOpen, totalQty } = storeToRefs(cartStore);

const openCart = () => {
    isOpen.value = true;
};
</script>
