<template>
    <div class="flex gap-2">
        <span class="flex flex-grow flex-col gap-1 px-2 py-1.5 border border-gray-400 rounded">
            <span class="text-sm text-gray-700 font-semibold leading-none">{{ new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(selectedVariant.price) }}</span>
            <span class="text-[10px] text-gray-500 uppercase leading-none">per {{ selectedVariant.title }}</span>
        </span>

        <QtyInput :model-value="qty" class="flex-shrink-0 text-sm" @update:model-value="updateQty" />
    </div>
</template>

<script setup>
import {
    ref, watchEffect,
} from 'vue';

import { watchDebounced } from '@vueuse/core';

import QtyInput from './QtyInput.vue';

import { useCartStore } from '@stores/cart';
const cartStore = useCartStore();

const props = defineProps({
    variants: {
        type: Array,
        required: true,
    },
});

const loading = ref(false);

const selectedVariant = ref();
const qty = ref(0);

watchEffect(() => {
    // Set the selected variant to the first available variant
    if (props.variants.length) {
        [selectedVariant.value] = props.variants;
    }

    // Set the qty to the quantity of the item within the cart if it exists
    if (cartStore) {
        const cartItem = cartStore.getItemById(selectedVariant.value.id);

        qty.value = cartItem ? cartItem.qty : 0;
    }
});

// When the qty changes update the cart to match
const updateQty = (value) => {
    cartStore.addItem(selectedVariant.value, value);
    qty.value = value;
};
</script>
