import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import getCsrfToken from '@utils/csrftoken';

const useCartStore = defineStore('cart', () => {
    const isOpen = ref(false);
    const loading = ref(false);
    const lineItems = ref([]);
    const totalQty = ref(0);
    const subtotal = ref(0);

    const getCart = async() => {
        loading.value = true;

        const response = await fetch('/actions/commerce/cart/get-cart', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        lineItems.value = data.cart.lineItems;
        totalQty.value = data.cart.totalQty;
        subtotal.value = data.cart.itemSubtotal;

        loading.value = false;
    };

    const getItemById = (id) => {
        return lineItems.value.find((element) => {
            return element.purchasableId == id;
        });
    };

    const addItem = async(item, qty) => {
        loading.value = true;

        const csrfToken = await getCsrfToken();

        let request = {
            CRAFT_CSRF_TOKEN: csrfToken.csrfTokenValue,
        };

        const lineItem = getItemById(item.purchasableId ?? item.id);

        if (lineItem) {
            request = {
                ...request,
                lineItems: {
                    [lineItem.id]: {
                        qty,
                    },
                },
            };
        } else {
            request = {
                ...request,
                purchasables: [
                    {
                        id: item.id,
                        options: {
                            image: item.image,
                        },
                        qty,
                    },
                ],
            };
        }

        const response = await fetch('/actions/commerce/cart/update-cart', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        const data = await response.json();

        console.log(data.cart);

        lineItems.value = data.cart.lineItems;
        totalQty.value = data.cart.totalQty;
        subtotal.value = data.cart.itemSubtotal;

        loading.value = false;
    };

    const removeItem = async(itemId) => {
        loading.value = true;

        const csrfToken = await getCsrfToken();

        const response = await fetch('/actions/commerce/cart/update-cart', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                CRAFT_CSRF_TOKEN: csrfToken.csrfTokenValue,
                lineItems: {
                    [itemId]: {
                        qty: 0,
                    },
                },
            }),
        });

        const data = await response.json();

        console.log(data.cart);

        lineItems.value = data.cart.lineItems;
        totalQty.value = data.cart.totalQty;
        subtotal.value = data.cart.itemSubtotal;

        loading.value = false;
    };

    return {
        isOpen,
        loading,
        lineItems,
        totalQty,
        subtotal,
        getItemById,
        getCart,
        addItem,
        removeItem,
    };
});

export {
    useCartStore,
};
